.component {
  @apply py-8;
}

.container {
  @apply flex flex-col h-full mx-auto gap-8 justify-center px-4;

  max-width: 1280px;
}

.reverse {
  @apply flex-col;
}

.content {
  @apply w-full;
}

.image {
  @apply w-full h-full;
}

@screen md {
  .component {
    @apply py-16;
  }

  .container {
    @apply flex-row gap-16 px-16;
  }

  .reverse {
    @apply flex-row-reverse;
  }

  .content {
    @apply w-1/2;
  }

  .image {
    @apply w-1/2 sticky;

    top: calc(88px + 1rem);
  }
}

@screen lg {
  .image {
    @apply relative;

    top: 0;
  }
}
