.component {
  @apply py-8 font-body;
}

.container {
  @apply flex flex-col h-full mx-auto gap-4 items-center px-4;

  max-width: 1280px;
}

.title {
  @apply text-base uppercase text-gray-300 text-center font-normal;
}

.partners {
  @apply m-0 px-0 pb-4 w-full flex flex-row gap-8 items-center snap-mandatory snap-x;

  list-style: none;
  overflow-x: scroll;
}

.partner {
  @apply snap-end flex items-center justify-center;

  max-height: 100%;
}

@screen md {
  .component {
    @apply py-16;
  }

  .container {
    @apply px-16;
  }
}

@screen lg {
  .partners {
    @apply justify-evenly;
  }
}
