.component {
  @apply py-8 bg-primary-300 text-gray-500 font-heading font-normal;

  clip-path: polygon(100% 0,100% 10%,100% 100%, 0% 100%,0 100%, 0 5%);
}

.container {
  @apply flex flex-col h-full mx-auto gap-4 items-center px-4 py-12;

  max-width: 1280px;
}

.content {
  @apply text-left flex gap-4 flex-col w-full;
}

.ctas {
  @apply flex flex-col mt-4 gap-4 w-full h-auto items-start justify-start;
}

.cta {
  @apply text-gray bg-white px-10 py-2 rounded-full border border-solid border-transparent shadow-lg;

  text-decoration: none;
  transition: all .2s ease-in-out;

  &:hover {
    @apply bg-transparent border-gray;
  }
}

.title {
  @apply m-0 text-clamp-title font-bold;
}

.description {
  @apply m-0 text-lg font-normal;
}

@screen sm {
  .ctas {
    @apply flex-row justify-center items-center;
  }

  .content {
    @apply text-center;

    max-width: 968px;
  }
}

@screen md {
  .component {
    clip-path: polygon(100% 0, 100% 10%, 100% 100%, 0% 100%, 0 100%, 0 15%);
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .container {
    @apply px-16;
  }
}
