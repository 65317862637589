.component {
  @apply text-gray bg-white px-10 py-2 rounded-full shadow-md z-20;

  text-decoration: none;
  transition: all .2s ease-in-out;

  &:hover {
    @apply bg-gray-100 border-gray z-20 shadow-lg shadow-black/20;
  }

  &:focus {
    @apply outline-offset-4;
  }
}

.small {
  @apply px-6 py-2 !important;
}

.cta {
  @apply bg-primary text-white z-20;

  &:hover {
    @apply bg-primary-700 border-primary;
  }
}

.transparent {
  @apply bg-none text-gray px-4 py-2 shadow-none !important;

  &:hover {
    @apply border-none bg-none !important;
  }
}
