.component {
  @apply py-8 w-full bg-primary-500;
}

.container {
  @apply flex flex-col h-full px-4 mx-auto text-white divide-y divide-x-0 divide-solid;

  max-width: 1280px;
}

.block {
  @apply flex flex-col gap-4 w-full py-8 list-none font-body font-extralight;
}

.title {
  @apply text-base font-bold m-0 font-heading;
}

.linkList {
  @apply list-none m-0 p-0 flex flex-col gap-4;
}

.copyrightContainer {
  @apply flex flex-col h-full mx-auto pt-8 justify-center px-4;

  max-width: 1280px;
}

.copyright {
  @apply text-sm m-0 w-full font-light text-white flex flex-col justify-between text-left gap-8;
}

.description {
  @apply m-0;
}

.link {
  color: inherit;
  max-width: fit-content;
}

.socialMedia {
  @apply flex flex-row gap-4 list-none m-0 p-0 flex-wrap font-body;
}

.socialMediaLink {
  color: inherit;
}

.logo {
  @apply m-0 mt-8;
}

@screen md {
  .container {
    @apply divide-y-0 divide-x flex-row;
  }

  .copyrightContainer {
    @apply pt-16;
  }

  .copyright {
    @apply flex-row gap-0;
  }

  .block {
    @apply px-8 py-0;
  }

  .logo {
    @apply mt-auto;
  }
}
