.component {
  @apply relative inline-block;
}

.styledUnderline {
  &:before {
    @apply bg-primary-200 absolute w-full h-4 left-0 bottom-1;

    width: 103%;
    left: -3px;
    content: '';
    z-index: -1;
    transform: skew(2deg, -1deg);
  }
}

.styledColor {
  @apply text-primary-500;
}
