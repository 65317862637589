.component {
  @apply bg-white w-full shadow-md fixed z-50 font-body;

  height: 88px;
  max-height: 88px;
}

.container {
  @apply flex items-center justify-between h-full px-4 mx-auto gap-16;

  max-width: 1280px;
}

.prefix {
  @apply text-black px-6 py-2 rounded-full border border-solid border-transparent;
}

.nav {
  @apply hidden;
}

.navItems {
  @apply ml-auto flex items-center gap-2 font-heading;
}

@screen sm {
  .nav {
    @apply flex gap-2 items-center;
  }
}
