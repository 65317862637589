.component {
  @apply w-full h-full m-0 p-0 rounded-xl relative;

  img {
    @apply w-full h-full object-cover object-center rounded-xl relative;
  }

  &::before {
    @apply absolute inset-0 rounded-xl bg-primary-100;

    content: '';
    width: 100%;
    height: 100%;
  }
}

.tiltLeft {
  &::before {
    transform: rotate(-3deg);
  }
}

.tiltRight {
  &::before {
    transform: rotate(3deg);
  }
}
