.component {
  @apply py-28 bg-primary-100;

  clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 97%);
  transform: translateY(-2.5rem);
}

.container {
  @apply flex flex-col h-full mx-auto gap-8 px-2;

  max-width: 1280px;
}

.content {
  @apply w-full;
}

.usps {
  @apply w-full grid grid-cols-2 gap-8;
}

.usp {
  @apply flex flex-col items-center justify-start gap-2 font-body font-bold;

  text-align: center;

  figure {
    @apply flex items-center justify-center w-16 h-16 p-4 bg-primary-500 rounded-full;
  }
}

.uspTitle {
  @apply m-0 text-sm text-center px-8;
}

@screen md {
  .component {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 95%);
  }

  .container {
    @apply flex-row gap-16 px-16;
  }

  .content {
    @apply w-1/2;
  }

  .usps {
    @apply w-1/2 gap-x-8;

    word-break: normal;
  }

  .uspTitle {
    @apply px-0;
  }
}

@screen lg {
  .uspTitle {
    @apply px-8;
  }
}

@screen xl {
  .uspTitle {
    @apply px-16;
  }
}
