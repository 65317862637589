.component {
  @apply w-full text-gray bg-primary-200 relative z-40 pb-32 font-body;

  padding-top: calc(88px + 6rem);
  clip-path: polygon(0 0, 100% 0, 100% 97%, 0 100%, 0 100%);
  background-image:
    radial-gradient(circle at top, theme('colors.transparent') 30%, theme('colors.primary.300') 80%),
    linear-gradient(180deg, theme('colors.white') 0%, theme('colors.primary.100') 100%);
}

.background {
  @apply absolute m-0;

  width: 80%;
  max-width: 600px;
  height: fit-content;

  img {
    @apply w-full h-full object-contain;
  }
}

.backgroundTopLeft {
  @apply absolute m-0 left-0;

  top: 60px;

  img {
    object-position: left top;
  }
}

.backgroundBottomRight {
  @apply absolute m-0 bottom-0 right-0;

  transform: rotate(180deg);
  bottom: 0;

  img {
    object-position: right bottom;
  }
}

.container {
  @apply flex items-center flex-col justify-between h-full px-4 mx-auto gap-4 z-20 relative;

  max-width: 862px;
}

.title {
  @apply m-0 text-left font-bold z-20;

  font-size: clamp(2.5rem, 1.65rem + 1.75vw, 3.75rem);
  max-width: 100%;
  line-height: 1.2;
}

.subtitle {
  @apply m-0 text-left font-normal z-20;

  font-size: clamp(1.5rem, 1.75vw, 1.75rem);
}

.actions {
  @apply flex flex-col gap-4 justify-start items-start z-20 w-full mt-8;
}

.heroImage {
  @apply w-full h-full max-w-full z-20 mt-16;
}

.imagePerson {
  @apply absolute m-0 -bottom-32 right-0 z-30 select-none;

  width: 40%;

  img {
    @apply w-full h-full object-contain object-bottom;
  }
}

@screen xs {
  .background {
    width: 70%;
  }
}

@screen sm {
  .background {
    width: 70%;
  }
}

@screen md {
  .actions {
    @apply flex-row justify-center;
  }

  .subtitle {
    @apply text-center;
  }

  .title {
    @apply text-center;
  }

  .background {
    width: 60%;
  }

  .imagePerson {
    right: 0;
  }
}

@screen lg {
  .background {
    width: 40%;
  }

  .imagePerson {
    right: -5rem;
  }
}

@screen xl {
  .background {
    width: 40%;
  }
}
