.component {
  @apply text-left font-heading font-normal;
}

.preTitle {
  @apply uppercase text-base tracking-wide font-light;
}

.title {
  @apply text-clamp-title font-bold;
}

.description {
  @apply text-lg;
}

.list {
  @apply mt-8 pl-0 text-primary font-bold flex flex-col gap-8;
}

.listItem {
  @apply flex items-start gap-4 w-full;

  line-height: 1.3rem;

  figure {
    @apply w-10 h-10 m-0 rounded-full bg-primary-500 items-center justify-center flex;

    min-width: 2.5rem;
    max-width: 2.5rem;

    img {
      @apply w-3/5 h-3/5 object-contain;
    }
  }
}

@screen sm {
  .list {
    @apply flex-wrap flex-row;

    column-gap: 2rem;
  }
}

@screen md {
  .list {
    @apply justify-between;
  }

  .listItem {
    width: 100%;
  }
}

@screen lg {
  .list {
    @apply flex-wrap flex-row;
  }

  .listItem {
    width: 45%;
  }
}
